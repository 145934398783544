import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import { Helmet } from "react-helmet";
import { Link } from 'gatsby'

import imageKid from '../images/image-kid.jpg';

const NotFoundPage = ({data}) => (
  <Layout>
    <Helmet>
      <title>404 | {data.site.siteMetadata.title}</title>
    </Helmet>
    <main className="page page--404">
      <section className="slab slab--hero">
        <Header siteTitle="Rob DiMarzo" /> 
          <div className="slab__inner">
            <p className="pageblock__content">
              Uh oh, you've found a page that doesn't exist!
            </p>
            <p>
              <img src={imageKid}/>
            </p>
            <p className="pageblock__content">
              Please accept this throwback as an apology.
            </p>
            <p className="pageblock__content">
              <Link className="btn btn--ghost" to="/">
                Return to homepage
              </Link>
            </p>
          </div>
      </section>
    </main>
  </Layout>
)
export const query = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default NotFoundPage
